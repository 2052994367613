import React from 'react'
import { motion } from 'framer-motion'
import { variants, transition } from '../utils/transitions'
import './index.scss'
import Button from '../partials/Button'

function Success() {

  return (
    <motion.div className='successPage' initial="out" animate="in" exit="out" variants={variants} transition={transition}>
        <div className='successPage-content'>
          <h2>You're all set</h2>
          <p>Thank you! You can access the stream from the upper right corner Login button.</p>
        </div>
        <div className='successPage-links'>
            <Button label="Back" link="/" back />
          </div>
    </motion.div>
  )
}

export default Success