import React, { useEffect, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import * as Bi from "react-icons/bi";
import * as Tb from "react-icons/tb";

function Chat({ socket, username, room }) {
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [isHidenChat, setIsHidenChat] = useState(true);

  const sendMessage = () => {
    if (currentMessage !== "") {
      const messageData = {
        room: room,
        author: username,
        message: currentMessage,
        id: `${socket.id}${Math.random()}`,
        socketID: socket.id,
        time:
          new Date(Date.now()).getHours() +
          ":" +
          new Date(Date.now()).getMinutes(),
      };

      socket.emit("send_message", messageData);
      setMessageList((list) => [...list, messageData]);
      setCurrentMessage("");
    }
  };

  useEffect(() => {
    socket.on("receive_message", (data) => {
      setMessageList((list) => [...list, data]);
    });
  }, [socket]);

  const hideChat = () => setIsHidenChat(true);

  if (isHidenChat)
    return (
      <div
        onClick={() => setIsHidenChat(false)}
        className="absolute w-[50px] h-[50px] right-[20px] z-50 bg-teal-600 bottom-[5px] rounded-full overflow-hidden shadow-xl cursor-pointer flex justify-center items-center"
      >
        <Tb.TbMessageCircle2 className="text-white text-3xl pointer-events-none" />
      </div>
    );

  return (
    <div className="chat-window absolute right-[20px] z-50 bg-neutral-900 bottom-[5px] rounded-md overflow-hidden shadow-xl max-w-[95%] md:max-w-[auto]">
      <div className="chat-header bg-neutral-900 flex justify-between items-center px-2">
        <p className="text-left">Live Chat</p>
        <div onClick={hideChat}>
          <Bi.BiX className="text-white text-3xl pointer-events-none" />
        </div>
      </div>
      <div className="chat-body bg-neutral-900">
        <ScrollToBottom className="message-container">
          {messageList.map((messageContent) => {
            return (
              <div
                key={messageContent.id}
                className="message"
                id={username === messageContent.author ? "you" : "other"}
              >
                <div>
                  <div className="message-content rounded-full bg-teal-600 px-2">
                    <p>{messageContent.message}</p>
                  </div>
                  <div className="message-meta text-gray-600">
                    <p id="time">{messageContent.time}</p>
                    <p id="author">{messageContent.author}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </ScrollToBottom>
      </div>
      <div className="flex relative h-[60px] w-[100%] gap-[10px] items-center justify-between p-2">
        <div className=" w-[100%]">
          <input
            type="text"
            className="py-2 px-4 w-[100%] rounded-full bg-neutral-600 text-white placeholder:text-white outline-none"
            value={currentMessage}
            placeholder="Hey..."
            onChange={(event) => {
              setCurrentMessage(event.target.value);
            }}
            onKeyPress={(event) => {
              event.key === "Enter" && sendMessage();
            }}
          />
        </div>
        <button
          className="w-[100px] py-3 bg-teal-600 text-white rounded-full text-sm font-semibold"
          onClick={sendMessage}
        >
          Send
        </button>
      </div>
    </div>
  );
}

export default Chat;
