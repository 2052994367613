import React, { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../context/userContext";
import { ReactComponent as Logo } from "../../images/logo_2.svg";

export default function Header() {
  const user = useContext(UserContext);

  const navigationHeader = [
    {
      id: 1,
      name: "Plans",
      href: "plans",
      show: user,
    },
    {
      id: 2,
      name: "Join a room",
      href: "stream",
      show: user,
    },
    {
      id: 3,
      name: "Sign In",
      href: "signin",
      show: !user,
    },
    {
      id: 4,
      name: "Register",
      href: "register",
      show: !user,
    },
    {
      id: 5,
      name: "Logout",
      href: "logout",
      show: user,
    },
  ];
  return (
    <div className="bg-neutral-900 relative px-2 py-0 flex justify-between items-center">
      <Logo className="max-h-[70px] max-w-[300px]" />
      <div className="flex justify-center space-x-6">
        {navigationHeader.map((item) =>
          item.show ? (
            <Link
              key={item.id}
              to={item.href}
              className="text-gray-400 hover:text-gray-500"
            >
              <span>{item.name}</span>
            </Link>
          ) : null
        )}
      </div>
    </div>
  );
}
