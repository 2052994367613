import React from "react";

export default function CamerasGrid({ handleCameraClick, selectedCamera }) {
  const cameras = [
    {
      title: "Main Camera",
      requireSubscription: false,
    },
    {
      title: "Overview Camera",
      requireSubscription: true,
    },
    {
      title: "Pool Table",
      requireSubscription: true,
    },
    {
      title: "Oustide View",
      requireSubscription: true,
    },
    {
      title: "Bar View",
      requireSubscription: true,
    },
    {
      title: "Subscriptions Only",
      requireSubscription: true,
    },
  ];

  return (
    <ul className="relative w-[100%] px-4 md:px-0 grid grid-cols-2 md:w-[650px] md:grid-cols-3 mx-auto gap-x-2 gap-y-2">
      {cameras.map((camera, id) => (
        <li
          key={camera.title}
          id={id}
          onClick={(e) => handleCameraClick(e, id)}
          className={`relative h-[120px] rounded-md ${
            id === selectedCamera ? "bg-teal-600" : "bg-neutral-900"
          } cursor-pointer flex flex-col items-center justify-center`}
        >
          <div className="group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg pointer-events-none">
            <button
              type="button"
              className="focus:outline-none px-3 rounded-full bg-neutral-600 outline-none"
            >
              Play
            </button>
            <h3 className="text-white mt-2 uppercase">{camera.title}</h3>
          </div>
        </li>
      ))}
    </ul>
  );
}
