import { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

import { getFunctions, httpsCallable } from "firebase/functions";

import db, { firebaseApp } from "../../Firebase";
import Loader from "../../common/Loader";
import UserContext from "../../context/userContext";
import SubscriptionContext from "../../context/subscriptionContext";

const Plans = () => {
  const [products, setProducts] = useState({});
  const user = useContext(UserContext);
  const [subscription, setSubscription] = useContext(SubscriptionContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    db.collection("customers")
      .doc(user.uid)
      .collection("subscriptions")
      .where("status", "==", "active")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (subscription) => {
          setSubscription({
            role: subscription.data().role,
            current_period_end: subscription.data().current_period_end.seconds,
            current_period_start:
              subscription.data().current_period_start.seconds,
            canceled_at: subscription.data().canceled_at?.seconds,
          });
        });
      });
  }, [user.uid, setSubscription]);

  useEffect(() => {
    setLoading(true);
    db.collection("products")
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        const productPromises = querySnapshot.docs.map(async (productDoc) => {
          const productData = productDoc.data();
          const priceSnap = await productDoc.ref.collection("prices").get();
          productData.prices = priceSnap.docs.reduce((acc, price) => {
            acc = { [price.id]: price.data(), priceId: price.id };
            return acc;
          }, {});
          return { id: productDoc.id, data: productData };
        });

        Promise.all(productPromises).then((productArray) => {
          const products = productArray.reduce((acc, product) => {
            acc[product.id] = product.data;
            return acc;
          }, {});

          let sortedProducts = {};
          Object.keys(products)
            .sort((a, b) => {
              return (
                products[a].prices[Object.keys(products[a].prices)[0]]
                  .unit_amount -
                products[b].prices[Object.keys(products[b].prices)[0]]
                  .unit_amount
              );
            })
            .forEach((key) => {
              sortedProducts[key] = products[key];
            });

          setProducts(sortedProducts);
          setLoading(false);
        });
      });
  }, []);

  const loadCheckout = async (priceId) => {
    setLoading(true);
    if (Object.keys(subscription).length > 0) {
      redirectToPortal();
    } else {
      const docRef = await db
        .collection("customers")
        .doc(user.uid)
        .collection("checkout_sessions")
        .add({
          price: priceId,
          success_url: window.location.origin + `/#/plans`,
          cancel_url: window.location.origin + `/#/plans`,
        });
      docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          setLoading(false);
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          const stripe = await loadStripe(
            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
          );
          stripe.redirectToCheckout({ sessionId });
        }
      });
    }
  };

  const redirectToPortal = async () => {
    const functions = getFunctions(firebaseApp, "us-central1");
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );

    const { data } = await functionRef({
      returnUrl: window.location.origin + `/#/plans`,
      locale: "auto", // Optional, defaults to "auto"
    });
    window.location.assign(data.url);
  };

  return (
    <div className="bg-white min-h-full">
      <div className="bg-gradient-to-b from-white to-gray-50">
        <div className="mx-auto max-w-7xl px-6 pt-24 lg:px-8">
          <div className="sm:align-center sm:flex sm:flex-col">
            <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-center">
              Pricing Plans
            </h1>
          </div>
          {loading ? (
            <div className="p-5 h-32 w-full flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
              {products &&
                Object.entries(products).map(([productId, productData]) => {
                  const isCurrentPackage = productData.name
                    ?.toLowerCase()
                    .includes(subscription?.role);
                  return (
                    <div
                      key={productId}
                      className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm"
                    >
                      <div className="p-6 h-full">
                        <h2 className="text-lg font-medium leading-6 text-gray-900">
                          {productData.name}
                        </h2>
                        <p className="mt-4 text-sm text-gray-500 h-20">
                          {productData.description}
                        </p>
                        <div className="h-20">
                          <p className="mt-8">
                            <span className="text-4xl font-bold tracking-tight text-gray-900">
                              $
                              {parseFloat(
                                products[productId].prices[
                                  products[productId].prices.priceId
                                ].unit_amount / 100
                              ).toFixed(2)}
                            </span>{" "}
                            <span className="text-base font-medium text-gray-500">
                              /mo
                            </span>
                          </p>
                          {subscription && isCurrentPackage && (
                            <small className="p-0 m-0 mb-[-100px]">
                              {subscription.canceled_at ? "Expiry" : "Renewal"}{" "}
                              Date:{" "}
                              {new Date(
                                subscription?.current_period_end * 1000
                              ).toLocaleDateString()}
                            </small>
                          )}
                        </div>

                        <button
                          onClick={() =>
                            loadCheckout(productData.prices.priceId)
                          }
                          className={`mt-8 block w-full rounded-md border border-transparent  py-2 text-center text-sm font-semibold ${
                            isCurrentPackage
                              ? "text-purple-600 bg-white hover:bg-purple-700 hover:text-white border-purple-600"
                              : "text-white hover:bg-purple-700 bg-purple-600 disabled:bg-purple-400"
                          }`}
                          disabled={
                            !isCurrentPackage && subscription.canceled_at
                          }
                        >
                          {isCurrentPackage && !subscription.canceled_at
                            ? "Cancel Subscription"
                            : isCurrentPackage &&
                              subscription.canceled_at &&
                              subscription.current_period_end >=
                                new Date().getTime() / 1000
                            ? "Renew"
                            : "Subscribe"}
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Plans;
