import "./styles/global.scss";

import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { AnimatePresence } from "framer-motion";

import Home from "./components/Home";
import Subscribe from "./components/Subscribe";
import Success from "./components/Success";
import Stream from "./Stream";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Signin from "./components/SignIn";
import { auth } from "./Firebase";
import UserContext from "./context/userContext";
import Signup from "./components/Signup";
import Logout from "./components/Logout";
import ForgotPassword from "./components/ForgotPassword";
import SuccessMessage from "./components/SuccessMessage";
// import ResetPassword from "./components/PasswordReset";
import RequireAuth from "./routes/RequireAuth";
import Plans from "./components/Plans";
import SubscriptionContext from "./context/subscriptionContext";

function App() {
  const [user, setUser] = useState({});
  const [subscription, setSubscription] = useState({});

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        setUser({
          uid: userAuth.uid,
          email: userAuth.email,
        });
      } else {
        setUser(null);
      }
    });
  }, []);

  return (
    <UserContext.Provider value={user}>
      <SubscriptionContext.Provider value={[subscription, setSubscription]}>
        <Header />
        <div className="App overflow-x-hidden">
          <div className="background"></div>
          <AnimatePresence exitBeforeEnter>
            <Routes>
              <Route path="signin" element={<Signin />} />
              <Route path="register" element={<Signup />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route
                path="forgot-password/success"
                element={<SuccessMessage />}
              />
              {/* <Route path="reset-password" element={<ResetPassword />} />
            <Route path="reset-password/success" element={<SuccessMessage />} /> */}
              <Route path="plans" element={<Plans />} />
              <Route path="subscribe" element={<Subscribe />} />
              <Route path="success" element={<Success />} />
              <Route path="logout" element={<Logout />} />
              <Route element={<RequireAuth />}>
                <Route path="stream" element={<Stream />} />
              </Route>
              <Route path="/" exact element={<Home />} />
            </Routes>
          </AnimatePresence>
        </div>
        <Footer />
      </SubscriptionContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
