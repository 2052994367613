import { useRef, useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "firebase/functions";

import Form from "../../common/Form";
import Input from "../../common/input";
import { getErrorMessage } from "../../constants/errorMessages";
import { auth } from "../../Firebase";
import { verifyCaptcha } from "../../services/Captcha";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const captchaRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) return;
    setLoading(true);

    try {
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();

      const { data } = await verifyCaptcha(token);

      if (data.success) {
        await sendPasswordResetEmail(auth, email);
        navigate("/forgot-password/success", {
          replace: true,
          state: { message: "Email Sent Successfully" },
        });
      } else {
        setLoading(false);
        setError("Retry Recaptcha");
      }
    } catch (error) {
      setError(getErrorMessage[error.code]);
      setLoading(false);
    }
  };

  return (
    <Form
      title="Forgot Password"
      buttonText="Submit"
      onSubmit={handleSubmit}
      loading={loading}
    >
      <Input
        title="Email Address"
        name="email"
        type="email"
        value={email}
        errorMessage={error}
        error={error ? true : false}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete="email"
        required
      />
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        ref={captchaRef}
      />
    </Form>
  );
};

export default ForgotPassword;
