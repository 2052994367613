import React from "react";

export default function JoinRoom({ setUsername, room, setRoom, joinRoom }) {
  return (
    <div className="joinChatContainer items-center">
      <h3 className="text-2xl">Join A Chat</h3>
      <input
        type="text"
        placeholder="John..."
        className="outline-none"
        onChange={(event) => {
          setUsername(event.target.value);
        }}
      />
      <input
        type="text"
        placeholder="Room ID..."
        className="outline-none"
        value={room}
        onChange={(event) => {
          setRoom(event.target.value);
        }}
      />
      <button onClick={joinRoom}>Join A Room</button>
    </div>
  );
}
