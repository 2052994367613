const Input = ({
  title,
  name,
  type,
  value,
  onChange,
  errorMessage,
  error,
  ...props
}) => {
  return (
    <div>
      <label
        htmlFor="password"
        className="block text-sm font-medium text-gray-700"
      >
        {title}
      </label>
      <div className="mt-1">
        <input
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          {...props}
        />
        {errorMessage ? (
          <span className={`text-sm ${error && "text-red-600"}`}>
            {errorMessage}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default Input;
