import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SuccessMessage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const timerId = setTimeout(() => {
      navigate("/signin", { replace: true });
    }, 1000);

    return () => clearTimeout(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white">
        {location.state.message}
      </h2>
    </div>
  );
};
export default SuccessMessage;
