import { useState, useRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import "firebase/functions";

import Form from "../../common/Form";
import Input from "../../common/input";
import { getErrorMessage } from "../../constants/errorMessages";
import { auth } from "../../Firebase";
import { verifyCaptcha } from "../../services/Captcha";
import UserContext from "../../context/userContext";

const Signup = () => {
  let navigate = useNavigate();
  const user = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const captchaRef = useRef(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();

      const { data } = await verifyCaptcha(token);

      if (data.success) {
        await auth.createUserWithEmailAndPassword(email, password);
        navigate("/stream", { replace: true });
      } else {
        setLoading(false);
        setError("Retry Recaptcha");
      }
    } catch (err) {
      setLoading(false);
      setError(getErrorMessage[err.code]);
    }
  };

  useEffect(() => {
    if (user) navigate("/stream");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      title="Register your account"
      buttonText="Register"
      onSubmit={handleSubmit}
      loading={loading}
    >
      <Input
        title="Email Address"
        name="email"
        type="email"
        value={email}
        errorMessage={error}
        error={true}
        onChange={(e) => setEmail(e.target.value)}
        autoComplete="email"
        required
      />
      <Input
        title="Password"
        name="password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        minLength="6"
        maxLength="15"
        autoComplete="current-password"
        required
      />
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        ref={captchaRef}
      />
    </Form>
  );
};

export default Signup;
