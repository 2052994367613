export const variants= {
    in: {
        opacity: 1,
        transform: "scale(1)"
    },
    out: {
        opacity: 0,
        transform: "scale(.9)"
    }
  }

export const transition= {
    duration: 1,
    transition: "linear",
}