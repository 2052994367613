import backgroundVideo from "./video/video.mp4";
import logo from "./images/logo_2.svg";
import "./App.scss";
import VideoFeed from "./videofeed";

export default function Audio({ selectedCamera, videoIds }) {
  return (
    <div className="flex flex-col justify-center items-center mb-5">
      <video className="video object-cover overflow-hidden" loop autoPlay muted>
        <source src={backgroundVideo} type="video/mp4" />
      </video>

      {/* Logo */}
      <div className="logo">
        <img src={logo} alt="Logo" width="250" height="250" />
      </div>

      {/* Audio player
      <div className="audio-player" autoPlay>
        <audio controls autoPlay>
          <source
            src="https://cp13.shoutcheap.com:18142/stream"
            type="audio/mpeg"
          />
        </audio>
	  </div> 

{/* Video player */}

      <div className="videostream" autoPlay>
        <VideoFeed videoIds={videoIds} selectedCamera={selectedCamera} />
      </div>
    </div>
  );
}
