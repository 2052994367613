import React from 'react'
import { Link } from 'react-router-dom'
import {AiOutlineRight, AiOutlineLeft} from 'react-icons/ai'

import './index.scss';

function Button({link, label, sm, back}) {
  return (
    <>
      {!back && <Link className={sm ? "linkButton sm" : "linkButton"} to={link}>{label} <AiOutlineRight /></Link>}
      {back && <Link className={sm ? "linkButton sm" : "linkButton"} to={link}><AiOutlineLeft /> {label}</Link>}
    </>
  )
}

export default Button