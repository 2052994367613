import "./App.scss";
import io from "socket.io-client";
import { useContext, useEffect, useState } from "react";

import Chat from "./Chat";
import Audio from "./audio";
import JoinRoom from "./components/JoinRoom";
import CamerasGrid from "./components/CamerasGrid";
import SubscriptionModal from "./components/SubscriptionModal";
import subscriptionContext from "./context/subscriptionContext";
import db from "./Firebase";
import UserContext from "./context/userContext";

const socket = io.connect(process.env.REACT_APP_RAILWAY_BACKEND_URL);

function Stream() {
  const [username, setUsername] = useState("");
  const [room, setRoom] = useState(1);
  const [showChat, setShowChat] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState(0);
  const [isModuleOpen, setIsModuleOpen] = useState(false);
  const [subscription, setSubscription] = useContext(subscriptionContext);
  const user = useContext(UserContext);
  const [videoIds, setVideoIds] = useState([]);

  useEffect(() => {
    const videoIdsRef = db.collection("videoids").doc("QOHOPami2Vp4DGiLgd1b");
    // debugger;
    videoIdsRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          setVideoIds(doc.data().id);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  useEffect(() => {
    db.collection("customers")
      .doc(user.uid)
      .collection("subscriptions")
      .where("status", "==", "active")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (subscription) => {
          setSubscription({
            role: subscription.data().role,
            current_period_end: subscription.data().current_period_end.seconds,
            current_period_start:
              subscription.data().current_period_start.seconds,
            canceled_at: subscription.data().canceled_at?.seconds,
          });
        });
      });
  }, [user.uid, setSubscription]);

  const joinRoom = () => {
    if (username !== "" && room !== "") {
      socket.emit("join_room", room);
      setShowChat(true);
    }
  };

  const handleCameraClick = (e, id) => {
    var currentTime = new Date().getTime() / 1000; // convert to seconds
    if (id === 0) {
      setSelectedCamera(id);
    } else if (subscription.current_period_end < currentTime) {
      setIsModuleOpen(true);
    } else {
      setSelectedCamera(id);
    }
  };

  return (
    <div className="overflow-hidden max-w-[100%]">
      {isModuleOpen && (
        <SubscriptionModal
          isModuleOpen={isModuleOpen}
          setIsModuleOpen={setIsModuleOpen}
        />
      )}
      <div className="Stream mb-0 relative">
        <Audio videoIds={videoIds} selectedCamera={selectedCamera} />
        {!showChat ? (
          <JoinRoom
            setUsername={setUsername}
            room={room}
            setRoom={setRoom}
            joinRoom={joinRoom}
          />
        ) : (
          <Chat socket={socket} username={username} room={room} />
        )}
        {showChat && (
          <CamerasGrid
            selectedCamera={selectedCamera}
            handleCameraClick={handleCameraClick}
          />
        )}
      </div>
    </div>
  );
}

export default Stream;
