import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default function VideoFeed({ selectedCamera, videoIds }) {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const opts = {
    height:
      windowSize.innerWidth > 850 ? "360" : `${windowSize.innerWidth * 0.5}px`,
    width:
      windowSize.innerWidth > 850 ? "640" : `${windowSize.innerWidth - 40}px`,

    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <YouTube
      videoId={videoIds[selectedCamera]}
      opts={opts}
      onReady={() => console.log("The video is ready!")}
    />
  );
}
